<template>
  <section id="print-surgery-files">
    <b-form-group>
      <label>Impressão</label>
      <b-row>
        <b-col cols="10">
          <CheckboxSelect
            v-if="!loading"
            :value="[]"
            trackBy="value"
            optionLabel="label"
            :label="options?.length ? 'Todos' : 'Nenhum arquivo disponível'"
            :placeholder="options?.length ? 'Selecionar' : ' '"
            :options="options"
            @onChange="setSelected"
          />
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
        <b-col cols="2">
          <b-button
            v-if="!loading"
            @click="handlePrint"
            :disabled="printing"
            variant="outline-primary"
            size="sm"
            class="w-100"
          >
            <Printer v-if="!printing" class="icon" />
            <b-spinner
              v-else
              variant="primary"
              class="spinner-loading mt-1 mb-1"
              small
            />
          </b-button>
          <b-skeleton
            v-else
            type="button"
            height="40px"
            class="w-100"
          ></b-skeleton>
        </b-col>
      </b-row>
    </b-form-group>
    <span class="hint">Selecione o que deseja imprimir</span>
  </section>
</template>

<script>
import { printFileGroup } from '@/utils/printHelper'

export default {
  name: 'PrintSurgeryFiles',

  components: {
    CheckboxSelect: () => import('@/components/CheckboxSelect'),
    Printer: () => import('@/assets/icons/printer.svg')
  },

  props: {
    surgeryId: { type: String, required: true },
    fileGroup: { type: Array, required: true }
  },

  data() {
    return {
      options: [],
      selected: [],

      loading: false,
      printing: false
    }
  },

  methods: {
    printFileGroup,
    handlePrint() {
      this.printing = true
      this.print()
      setTimeout(() => {
        this.printing = false
      }, 2000)
    },
    async print() {
      if (!this.selected.length) {
        return this.$toast.warning(
          'Selecione ao menos um arquivo para imprimir'
        )
      }

      await this.printFileGroup(this.selected)
    },
    setSelected(value) {
      this.selected = []
      if(value === null) {
        this.options.forEach(option => {
          if (option.value !== null) {
            this.selected.push(option)
          }
        })
        return
      }

      this.selected = value
    }
  },

  watch: {
    fileGroup: {
      handler(value) {
        this.options = []
        value.map(item => {
          if (!this.options.find(option => option.value === item.id) && item.path !== null) {
            this.options.push({
              value: item.id,
              label: item.name,
              path: item.path
            })
          }
        })

        this.options.unshift({
          value: null,
          label: 'Todos'
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#print-surgery-files {
  background-color: var(--neutral-000);
  width: 100%;
  height: 122.36px;
  padding: 15px;

  .hint {
    font-size: 0.8rem;
    color: var(--type-placeholder);
  }

  .icon {
    height: 24px;
    stroke: var(--blue-500);
  }

  .spinner-loading {
    height: 11px;
    width: 15px;
    padding: 5px;
  }

  .form-group {
    margin-bottom: 0 !important;

    button {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
