import Vue from 'vue'

export const getDocUrl = async (path) => {
  try {
    const res = await Vue.prototype.api.getDocTemporaryUrl(path)
    window.open(res.data, '_blank')
  } catch(err) {
    this.$Vue.prototype.error('Não foi possível abrir o arquivo, contate o suporte se o erro persistir.')
  } 
}

export const printFileGroup = async (fileGroup) => {
  fileGroup.map(async doc => {
    await getDocUrl(doc.path)
  })
}